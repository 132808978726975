import Vue from 'vue';
import Qs from 'qs';
import router from '@/router';
import {noHeader} from '@/common/http/noHeader';
import {AxiosError, AxiosResponse} from 'axios';
import {ElLoadingComponent} from 'element-ui/types/loading';
import {Util} from '@/common/util';
import {overdueCode} from '@/common/http/overdueCode';
import store from '@/store';

type PromiseInt<T> = (a: T) => void;

interface AxiosErrorMsg {
    message: string;
}

export interface AxiosData<T> {
    data: T;
    msg: string;
    code: number;
    page: number;
}

export interface Header {
    [more: string]: string;
}

export class Axios {
    private vue: Vue;
    private CancelToken: any;
    private loading: ElLoadingComponent | undefined;
    public headers: Header = {};

    private redirectLoginTimer: any;

    constructor() {
        this.vue = new Vue();
    }

    public get<T>(url: string, data: any, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url, data);


        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        // data.mchId = localStorage.getItem('selectMchId');
        // if (!data.platform) {
        //     data.platform = 1;
        // }

        return new Promise((resolve, reject) => {
            this.vue.$axios.get(url, {
                params: this.clearParamsNull(data),
                headers: this.headers,
                cancelToken: this.CancelToken.token,
            }).then((result: AxiosResponse<AxiosData<T>>) => {
                this.cleanLoading(timer);
                this.setResult(result, resolve, reject);

            }).catch((err: AxiosErrorMsg | AxiosError) => {
                this.catchError(timer, err);

            });
        });
    }

    public getNoCode<T>(url: string, data: any,  json: any = false,setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url, data);

        let params: string | object;
        params = this.clearParamsNull(data);
        if (json !== true) {
            params = Qs.stringify(params);
        }

        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        return new Promise((resolve, reject) => {
            this.vue.$axios.get(url,
                {
                params: data,
                headers: this.headers,
                cancelToken: this.CancelToken.token,
                responseType: 'blob', // 添加这一行以指定响应类型为 'blob'
                }).then((result: AxiosResponse<AxiosData<T>>) => {
                this.cleanLoading(timer);
                resolve(result.data);
            }).catch((err: AxiosErrorMsg | AxiosError) => {
                this.catchError(timer, err);

            });
        });
    }

    public fileWithGet<T>(url: string, data: any, setLoading: boolean = false) {
        this.handleHttpAndHeader(url, data);
        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        data.mchId = localStorage.getItem('selectMchId');
        this.vue.$axios.get(
            url,
            {
                params: this.clearParamsNull(data),
                headers: this.headers,
                responseType: 'blob',
                cancelToken: this.CancelToken.token,
            }).then((res: AxiosResponse<Blob>) => {

            // clearTimeout(timer);
            // this.cleanLoading(timer);
            // new Util().getUploading(res.data, res);

            this.cleanLoading(timer);

            // if ((res as AxiosResponse<Blob>).data.type === 'application/json') {
            //     const reader = new FileReader();
            //     reader.onload = (event: Event) => {
            //         console.log(event.target.result)
            //         const result = JSON.parse((<any> event.target!).result);
            //         // const result = (<any>event.target!).result;
            //         Util.showToast(result.message, 'no');
            //     };

            //     reader.readAsText(res.data);
            //     return;
            // }

            new Util().getUploading((<AxiosResponse<Blob>>res).data, <AxiosResponse<Blob>>res);

        }).catch((err: AxiosError | AxiosErrorMsg) => {

            this.catchError(timer, err);

        });
    }

    public fileImport<T>(url: string, data: FormData, callBack?: Function, setLoading: boolean = false) {
        this.handleHttpAndHeader(url, data);
        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        this.headers['Content-Type'] = 'multipart/form-data';

        this.vue.$axios.post(url,
            data,
            {
                headers: this.headers,
                cancelToken: this.CancelToken.token,
            }).then((res: AxiosResponse<AxiosData<T>>) => {

            this.cleanLoading(timer);

            if (callBack) {
                callBack(res.data);
            }

        }).catch((err: AxiosErrorMsg | AxiosError) => {

            this.catchError(timer, err);

        });
    }

    public post<T>(url: string, data: any, json: any = false, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url, data);

        // if (!data.platform) {
        //     data.platform = 4;
        // }
        //
        // data.mchId = localStorage.getItem('selectMchId');
        let params: string | object;
        params = this.clearParamsNull(data);
        if (json !== true) {
            params = Qs.stringify(params);
        }

        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }

        return new Promise((resolve, reject) => {
            this.vue.$axios.post(
                url,
                params,
                {
                    ...(typeof json == 'object' ? json : {}),
                    headers: this.headers,
                    cancelToken: this.CancelToken.token,
                },
            ).then((result: AxiosResponse<AxiosData<T>>) => {

                this.cleanLoading(timer);

                if (result.data) {
                    Object.assign(result.data, {
                        __params: params
                    })
                }

                if (json && json.responseType == 'blob') {
                    resolve(result.data)
                } else {
                    this.setResult(result, resolve, reject);
                }


            }).catch((err: AxiosErrorMsg | AxiosError) => {
                if (err.message !== '重复请求') {
                    reject(err);
                }
                this.catchError(timer, err);

            });
        });
    }

    private setResult<T>(result: AxiosResponse<AxiosData<T>>, resolve: PromiseInt<AxiosData<T>>, reject: PromiseInt<AxiosData<T>>): void {
        if (result.data.code === 200) {
            resolve(result.data);
        } else if (overdueCode.includes(result.data.code)) {
            reject(result.data);
            clearTimeout(this.redirectLoginTimer)
            this.redirectLoginTimer = setTimeout(() => {
                localStorage.clear();
                router.push('/login');
            }, 500);

        } else {
            const msg = result.data.message
            this.vue.$message.error(msg);
            reject(result.data);
        }
    }

    private cancelAxios(): void {
        try {
            this.CancelToken.cancel('重复请求');
        } catch {
            //    do nothing
        }
    }

    private setLoading(): number {
        const timer = setTimeout(() => {
            this.loading = this.vue.$loading({
                lock: true,
                text: '请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        }, 1000);

        return timer;
    }


    private cleanLoading(timer: number): void {

        clearTimeout(timer);

        try {
            this.loading!.close();

        } catch {
            //    do nothing
        }
    }

    public clearParamsNull<T>(data: any) {
        let resultParams: any = {};
        for (const i in data) {
            if (data[i] || data[i] === 0) {
                resultParams[i] = data[i];
            }

        }

        return resultParams;
    }

    private handleHttpAndHeader(url: string, data: any): void {
        const {__isUnique} = data
        if (__isUnique) { // 被阻塞和 阻塞请求都需要 加上 该参数
            this.cancelAxios();
        }
        this.CancelToken = this.vue.$axios.CancelToken.source();
        // this.headers = noHeader.includes(url) ? this.headers :
        //     {Authorization: localStorage.getItem('token_type')! + localStorage.getItem('access_token') !};

        if (noHeader.includes(url)) {
            delete this.headers['Authorization'];
        } else {
            // console.log(localStorage.getItem('token'), `localStorage.getItem('token')`)
            this.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }
        this.headers['ShopId'] = `${localStorage.getItem('ShopId')}`
        this.headers['platform'] = store.state.platformType === 2 ? '6' : '3'  // 3熊猫球社   6众享台球

    }

    private catchError(timer: number, err: AxiosErrorMsg | AxiosError): void {
        console.log(141616161651)
        this.cleanLoading(timer);

        if ((err as AxiosError).response && (<AxiosErrorMsg>err).message !== '重复请求') {


            if ((<AxiosError>err).response!.status === 500 || (<AxiosError>err).response!.status === 502) {
                this.vue.$message.error("服务器正在维护，请稍等片刻");
            } else {
                // this.vue.$message.error((err as AxiosError).response!.statusText);
            }

        }

    }

}
