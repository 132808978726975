import Vue from 'vue';
import router from '@/router/index';
import store from '@/store/index';

import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { Axios, AxiosData, Header } from '@/common/http/http';
import { Util } from '@/common/util';
import { AxiosResponse } from 'axios';


export class BasicsClass {
    protected vue: Vue;
    protected $router: VueRouter;
    protected $store: Store<any>;
    protected $axios: Axios;
    protected $headers: Header;

    constructor() {
        this.vue = new Vue();
        this.$router = router;
        this.$store = store;
        this.$axios = new Axios();
        this.$headers = this.$axios.headers;
    }


    public BasicGet(url: string, params: any, showSuccessMessage = false, showMessage = false, json:any = false, successCallBack?: Function, errCallBack?: Function, setLoading: boolean = false) {
        this.$axios.get(url, {
            ...params,
        }, setLoading).then(data => {
            showSuccessMessage && Util.showToast(data.msg);
            successCallBack && successCallBack(data);
        }).catch(err => {
            // if (err.code) {
            //     Util.showToast(err.msg, 'no');
            // // }
            errCallBack && errCallBack()
        });
    }

    public BasicGetNoCode(url: string, params: any, showSuccessMessage = false, showMessage = false, json:any = false, successCallBack?: Function, errCallBack?: Function, setLoading: boolean = false) {
        this.$axios.getNoCode(url, {
            ...params,
        },json,  setLoading).then(data => {
            showSuccessMessage && Util.showToast(data.msg);
            successCallBack && successCallBack(data);
        }).catch(err => {
            errCallBack && errCallBack()
        });
    }


    public BasicPost(url: string, params: any, showSuccessMessage = false, showMessage = false, json:any = false, successCallBack?: Function, errCallBack?: Function, setLoading: boolean = false) {
        this.$axios.post(url, {
            ...params,
        }, json, setLoading).then(data => {
            // Util.showToast('3521432511352');
            // showSuccessMessage && Util.showToast(data.msg);
            successCallBack && successCallBack(data);

        }).catch((err) => {
            console.log(err)
            // this.vue.$message.error('请求超时');
            errCallBack && errCallBack(err);
            if (err.message==="收银台链接已被篡改") {
                localStorage.clear();
                this.$router.replace('/login');
            }
            // if (!sessionStorage.getItem('showToast')) {
            //     sessionStorage.setItem('showToast', '123');
            // showMessage && Util.showToast(err.message, 'no');
            //     errCallBack && errCallBack(err);
            //     setTimeout(() => {
            //         sessionStorage.removeItem('showToast');
            //     }, 3000);
            // }

        });
    }


    public getFile(url: string, form?: any, callback?: Function) {
        if (!form.groupCode) {
            this.$axios.fileWithGet(url, {
                ...form,
            });
        } else if (form.groupCode.length > 0) {
            this.$axios.fileWithGet(url, {
                ...form,
                groupCode: form.groupCode[form.groupCode.length - 1],
            });
        } else {
            this.$axios.fileWithGet(url, {
                ...form,
            });
        }



        if (callback) {
            callback();
        }
    }



    public ImportFile<T, K>(url: string, file: FileList, fileKey: string, Form?: T, callBack?: Function): void {
        const fData = new FormData();
        console.log(fileKey, file[0])
        if (file[0]) {
            fData.append(fileKey, file[0]);
        }
        if (Form) {
            for (const i in Form) {
                fData.append(i, (Form[i] as any));
            }
        }

        this.$axios.fileImport<K>(url, fData, (data: AxiosData<K>) => {
            if (data.code === 200) {
                this.vue.$message.success(data.msg);

                if (callBack) {
                    callBack(data);
                }

            } else {

                // this.vue.$message.error(data.message);
                if (callBack) {
                    callBack(data);
                }

            }
        })
    }
}
